import { useEffect } from "react";
import { getOptionsFromWindow, isCustomize } from "../utils";
import { getCookieConsentManager } from "../others";
import { preDecisionGateway } from "@devowl-wp/cookie-consent-web-client";
import { preDecisionGatewayDynamicRequest, preDecisionGatewayHasPageClass, preDecisionGatewayIsCrawler, preDecisionGatewayIsPreventPreDecision, preDecisionGatewaySupportsCookies, preDecisionGatewayUserConsent, preDecisionGatewayIsDoNotTrack } from "@devowl-wp/cookie-consent-web-client";
import { commonRequest } from "@devowl-wp/utils";
import { locationRestConsentDynamicPredecisionGet } from "../wp-api";
/**
 * Do some decision gateways like DNT, Bot / Crawler and Decision and depending
 * on that, show the Banner initially.
 */
function useBannerPreDecisionGateway(banner, setContextValue, toggleOverlay) {
  useEffect(() => {
    // Only run this predecision mechanism once and when not in customize
    if (isCustomize()) {
      return;
    }
    const {
      restNamespace,
      restRoot,
      restQuery,
      restNonce,
      restPathObfuscateOffset,
      others: {
        isAcceptAllForBots,
        isPreventPreDecision,
        hasDynamicPreDecisions,
        isRespectDoNotTrack
      }
    } = getOptionsFromWindow();
    const {
      onSave
    } = banner;
    preDecisionGateway(getCookieConsentManager(), {
      gateways: [preDecisionGatewaySupportsCookies, preDecisionGatewayUserConsent, preDecisionGatewayHasPageClass(["login-action-"]), preDecisionGatewayIsCrawler(isAcceptAllForBots ? "all" : false), preDecisionGatewayIsDoNotTrack(isRespectDoNotTrack), preDecisionGatewayDynamicRequest(() => {
        const {
          clientWidth,
          clientHeight
        } = document.documentElement;
        return commonRequest({
          location: locationRestConsentDynamicPredecisionGet,
          options: {
            restNamespace,
            restRoot,
            restQuery,
            restNonce,
            restPathObfuscateOffset
          },
          sendRestNonce: false,
          request: {
            viewPortWidth: clientWidth,
            viewPortHeight: clientHeight
          },
          params: {
            _wp_http_referer: window.location.href
          }
        });
      }, 10000, hasDynamicPreDecisions), preDecisionGatewayIsPreventPreDecision(isPreventPreDecision)],
      args: [banner],
      onIsDoNotTrack: () => {
        onSave(true, "none");
      },
      onShowCookieBanner: () => setContextValue(state => {
        return {
          ...state,
          // Always revert back to "Essentials" only consent when cookie needs to be consented
          consent: {
            groups: getCookieConsentManager().getDefaultDecision()
          },
          ...toggleOverlay(state, true, setContextValue)
        };
      })
    });
  }, []);
}
export { useBannerPreDecisionGateway };