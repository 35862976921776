import { fastdom } from "@devowl-wp/web-scoped-css";
import { useCallback, useRef, useState } from "react";

/**
 * With the power of `SuspenseLoadedContext` we could "wait" for a list of suspended components
 * to be mounted to the DOM. This allows us to e.g. avoid CLS and render the cookie banner when
 * all suspended components are visible.
 *
 * @param waitForAnimationFrame If `true` it waits for the next animation frame before determining if
 *                              all components are visible. This is useful if you are using e.g. Preact
 *                              with `options.requestAnimationFrame = window.requestAnimationFrame`.
 */
function useSuspendedComponentsMounted(names) {
  let waitForAnimationFrame = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const [areAllSuspendedComponentsVisible, setAreAllSuspendedComponentsVisible] = useState(false);
  const allVisibleSuspendComponents = useRef([]);
  const onSuspenseMounted = useCallback(name => {
    allVisibleSuspendComponents.current.push(name);
    if (names.every(i => allVisibleSuspendComponents.current.indexOf(i) > -1)) {
      if (waitForAnimationFrame) {
        fastdom.mutate(() => setAreAllSuspendedComponentsVisible(true));
      } else {
        setAreAllSuspendedComponentsVisible(true);
      }
    }
  }, []);
  return [areAllSuspendedComponentsVisible, onSuspenseMounted];
}
export { useSuspendedComponentsMounted };