import _extends from "@babel/runtime/helpers/extends";
import { useBanner, BannerDottedGroupList, BannerTcfStacks, BannerGroupList, BannerTcfGroupList, BannerButtonList, BannerHistorySelect, useCommonTeachings, yieldLazyLoad } from "../../..";
import { useMemo } from "react";
import { extendBannerBodyStylesheet } from "@devowl-wp/web-cookie-banner";
import { useBannerStylesheet } from "../../../hooks";
const BodyDescription = yieldLazyLoad(import( /* webpackMode: "eager" */"../../common/body/description").then(_ref => {
  let {
    BodyDescription
  } = _ref;
  return BodyDescription;
}), "BodyDescription");
const BannerBody = _ref2 => {
  let {
    leftSideContainerRef,
    rightSideContainerRef
  } = _ref2;
  const {
    a11yIds,
    Container,
    RightSide,
    LeftSide
  } = useBannerStylesheet().extend(...extendBannerBodyStylesheet);
  const banner = useBanner();
  const {
    groups,
    tcf,
    individualPrivacyOpen,
    activeAction,
    individualTexts: {
      postamble
    }
  } = banner;
  const teachings = useCommonTeachings({
    services: groups.map(_ref3 => {
      let {
        items
      } = _ref3;
      return [...items];
    }).flat()
  });

  // Calculate the body content depending on TCF availability and if second view got opened
  const stackOrGroups = useMemo(() => {
    if (process.env.IS_TCF === "1" && tcf) {
      return individualPrivacyOpen ? h(BannerTcfGroupList, null) : h(BannerTcfStacks, null);
    } else {
      return individualPrivacyOpen ? h(BannerGroupList, null) : h(BannerDottedGroupList, null);
    }
  }, [individualPrivacyOpen, tcf]);
  const leftSideContainer = h(LeftSide, {
    ref: leftSideContainerRef,
    key: "leftSide"
  }, h(BodyDescription, _extends({
    id: a11yIds.description
  }, teachings), activeAction === "history" && h(BannerHistorySelect, null)), stackOrGroups, !!postamble && individualPrivacyOpen && h(BodyDescription, {
    teachings: [postamble],
    isPostamble: true
  }));
  const rightSideContainer = activeAction === "history" ? h("div", {
    ref: rightSideContainerRef
  }) : h(RightSide, {
    ref: rightSideContainerRef,
    key: "rightSide"
  }, h(BannerButtonList, null));
  return h(Container, null, h("div", null, individualPrivacyOpen ? [rightSideContainer, leftSideContainer] : [leftSideContainer, rightSideContainer]));
};
export { BannerBody };