import { useBanner, useBannerStylesheet, yieldLazyLoad } from "../../..";
import { useRef, useEffect, useCallback } from "react";
import { extendBannerContentStylesheet, extendCommonButtonsStylesheet, fastdom } from "@devowl-wp/web-cookie-banner";
const BannerHeader = yieldLazyLoad(import( /* webpackMode: "eager" */"../header/header").then(_ref => {
  let {
    BannerHeader
  } = _ref;
  return BannerHeader;
}), "BannerHeader");
const BannerBody = yieldLazyLoad(import( /* webpackMode: "eager" */"../body/body").then(_ref2 => {
  let {
    BannerBody
  } = _ref2;
  return BannerBody;
}), "BannerBody");
const BannerFooter = yieldLazyLoad(import( /* webpackMode: "eager" */"../footer/footer").then(_ref3 => {
  let {
    BannerFooter
  } = _ref3;
  return BannerFooter;
}), "BannerFooter");
const BannerContent = () => {
  const {
    Content,
    hideOnMobileClass,
    dimsContent,
    dimsOverlay,
    dimsHeader,
    dimsFooter,
    dimsRightSidebar,
    A11ySkipToLink,
    a11yIds: {
      firstButton
    }
  } = useBannerStylesheet().extend(...extendCommonButtonsStylesheet).extend(...extendBannerContentStylesheet);
  const {
    decision: {
      acceptAll,
      acceptEssentials,
      showCloseIcon
    },
    mobile,
    individualPrivacyOpen,
    bodyDesign: {
      acceptEssentialsUseAcceptAll
    },
    activeAction,
    pageRequestUuid4,
    i18n: {
      skipToConsentChoices
    }
  } = useBanner();
  const ref = useRef();
  const useAcceptEssentials = acceptEssentialsUseAcceptAll && acceptAll === acceptEssentials ? acceptAll : acceptEssentials;
  const headerClassName = mobile.hideHeader && !activeAction && !individualPrivacyOpen && (useAcceptEssentials === "hide" ? !showCloseIcon : true) ? hideOnMobileClass : "";

  // As we use `<Suspense` components we cannot use `useEffect` as `ref.current` is `null`
  const dimsDisposer = useRef();
  dimsDisposer.current = dimsDisposer.current || {};
  const getDimsDependencies = useCallback(() => [document.querySelector(`#${pageRequestUuid4} div[class*="animate__"]`)], [pageRequestUuid4]);
  const updateRefDims = useCallback((_ref4, node) => {
    let [observer,, disconnect] = _ref4;
    if (node) {
      observer(node, getDimsDependencies());
    } else {
      disconnect();
    }
  }, [getDimsDependencies]);
  const refHeaderDims = useCallback(node => updateRefDims(dimsHeader, node), [updateRefDims]);
  const refRightSidebar = useCallback(node => updateRefDims(dimsRightSidebar, node), [updateRefDims]);
  const refFooterDims = useCallback(node => updateRefDims(dimsFooter, node), [updateRefDims]);
  useEffect(() => {
    const animationContainer = getDimsDependencies();
    const disposeFns = [dimsContent[0](ref.current), dimsOverlay[0](document.querySelector(`#${pageRequestUuid4}`), animationContainer)];
    return () => disposeFns.forEach(c => c());
  }, []);
  useEffect(() => {
    // Scroll to top automatically at first visit (e.g. `<dialog` auto focuses the first found element)
    fastdom.mutate(() => setTimeout(() => ref.current.scrollTop = 0, 0));
  }, [individualPrivacyOpen]);
  return h(Content, {
    ref: ref
  }, h(A11ySkipToLink, {
    href: `#${firstButton}`
  }, skipToConsentChoices), h(BannerHeader, {
    ref: refHeaderDims,
    className: headerClassName
  }), h(BannerBody, {
    rightSideContainerRef: refRightSidebar
  }), h(BannerFooter, {
    ref: refFooterDims
  }));
};
export { BannerContent };